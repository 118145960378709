import React from "react";
import "./styles.scss";
import eventTrigger from "./images/event-trigger.png";
const TopBanner = () => {
  return (
    <section className="commonSectionWrapper">
      <div className="containerWrapper">
        <div className="topBannerWrapperEventtriggers">
          <div className="col-md-6 col-sm-6 col-xs-12 noPadd">
            <h1 className="articleTitle">Trigger webhooks on database events</h1>
            <div className="articleDescTitle fontBold">
              <a
                href="https://github.com/hasura/graphql-engine/blob/master/event-triggers.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                Open-source
              </a>{" "}
              • Use with Serverless • Reliable delivery • Add to existing Postgres
            </div>
            <div className="buttonWrapper">
              <a href="https://cloud.hasura.io/signup?pg=event-triggers&plcmt=body&cta=try-hasura-cloud&tech=default">
                <button className="commonBtn pinkBtn">Try Hasura Cloud</button>
              </a>
              <div className="commonLink">
                <a
                  href="https://hasura.io/docs/latest/graphql/core/event-triggers/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View event trigger docs <div className="arrow">→</div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 noPadd">
            <div className="eventTriggerImg">
              <img src={eventTrigger} alt="eventTrigger" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
