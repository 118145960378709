import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/eventtriggers/topbanner";
import BuildReactive from "../components/eventtriggers/buildreactive";
import CreateEventTrigger from "../components/eventtriggers/createeventtrigger";
import AtomicReliable from "../components/eventtriggers/atomicreliable";
import CommonVideoWrapper from "../components/common/commonvideowrapper";
import ProductGetStarted from "../components/product/productgetstarted";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/eventtriggers.png",
};
const canonicalUrl = "https://hasura.io/event-triggers/";
const EventTriggers = props => (
  <Layout location={props.location}>
    <Seo
      title="Trigger webhooks on database events | Hasura GraphQL Engine"
      description="Hasura comes with an event-trigger system on PostgreSQL that allows developers to trigger serverless functions or webhooks on changes in the database."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner />
    <BuildReactive />
    <CreateEventTrigger />
    <AtomicReliable />
    <CommonVideoWrapper
      title={
        <h2 className="articleSubTitle">
          Build reactive UX for your async backend with realtime GraphQL
        </h2>
      }
      subDescription="Propagate event driven and asynchronous information to UI clients easily with GraphQL subscriptions & live-queries."
      videoLink="https://www.youtube.com/embed/kTSOxRrtCeI?showinfo=0&rel=0"
      bgClass="commonWhiteBgColor"
      reverse={false}
      idLink="build-reactive-backend"
    />
    <CommonVideoWrapper
      title={<h2 className="articleSubTitle">Ready to observe, monitor and debug</h2>}
      subDescription="Generated events are automatically instrumented with an event-id and structured logs emitted by Hasura make it easy for you to use your favourite tools to run an event-driven backend in production."
      videoLink="https://www.youtube.com/embed/WOPA52r3bzU?showinfo=0&rel=0"
      bgClass="commonWhiteBgColor"
      reverse
      idLink="monitor-debug"
    />
    <div className="commonSectionWrapper removePaddBottom">
      <div className="containerWrapper">
        <ProductGetStarted
          linkUrl="https://cloud.hasura.io/signup?pg=event-triggers&plcmt=body&cta=start-for-free&tech=default"
          title="Get started today on Hasura Cloud"
          linkContent="Start for free"
        />
      </div>
    </div>
  </Layout>
);

export default EventTriggers;
