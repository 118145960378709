import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
const TabPanelList = ({
  description,
  subDescription,
  isBtn,
  btnLabel,
  btnLink,
  tutorialLabel,
  tutorialLink,
  videoLink,
  videoTitle,
}) => {
  return (
    <div className="panelWrapper wd100">
      <div className="col-md-6 col-sm-6 col-xs-12 noPadd addPaddRight">
        <div className="articleDesc fontBold">{description}</div>
        <div className="articleDesc">{subDescription}</div>
        {isBtn ? (
          <div className="commonLink">
            <a href={btnLink} target={"_blank"} rel="noopener noreferrer">
              {btnLabel} <div className="arrow">→</div>
            </a>
          </div>
        ) : null}
        <div className="commonLink">
          <a href={tutorialLink} target={"_blank"} rel="noopener noreferrer">
            {tutorialLabel} <div className="arrow">→</div>
          </a>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 col-xs-12 noPadd">
        <div className="videoWrapper">
          <div className="videoAspectRatio">
            <iframe
              loading="lazy"
              title={videoTitle}
              src={videoLink}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
TabPanelList.propTypes = {
  description: PropTypes.string.isRequired,
  subDescription: PropTypes.string.isRequired,
  isBtn: PropTypes.bool.isRequired,
  btnLabel: PropTypes.string.isRequired,
  btnLink: PropTypes.string.isRequired,
  tutorialLabel: PropTypes.string.isRequired,
  tutorialLink: PropTypes.string.isRequired,
  videoLink: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
};
export default TabPanelList;
