import React, { useState } from "react";
// import PropTypes from "prop-types"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TabPanelList from "./tabpanellist";
import "./styles.scss";
const BuildReactive = () => {
  const [useTabIndex, setuseTabIndex] = useState(0);
  return (
    <section id="build-reactive" className="commonSectionWrapper">
      <div className="containerWrapper">
        <div className="buildReactiveWrapper">
          <h2 className="articleTitle">Build reactive & async features easily</h2>
          <Tabs
            className="commonTabWrapper wd100"
            selectedIndex={useTabIndex}
            onSelect={useTabIndex => setuseTabIndex(useTabIndex)}
          >
            <TabList className="commonTabNav text-center wd100 tabPosition">
              <Tab className={"commonTabList" + (useTabIndex === 0 ? " commonTabListActive" : "")}>
                <div className="tabListHeader">NOTIFICATIONS</div>
              </Tab>
              <Tab className={"commonTabList" + (useTabIndex === 1 ? " commonTabListActive" : "")}>
                <div className="tabListHeader">DATA TRANSFORMATION (ETL)</div>
              </Tab>
              <Tab className={"commonTabList" + (useTabIndex === 2 ? " commonTabListActive" : "")}>
                <div className="tabListHeader">UPDATE STATIC SITES</div>
              </Tab>
            </TabList>
            <TabPanel className="commonTabPanel">
              <TabPanelList
                description="Trigger push notifications and emails based on database events."
                subDescription="Try the demo and tutorial below to see how browser push notifications are triggered when the user inserts some data."
                isBtn
                btnLabel="Try it out"
                btnLink="https://serverless-push.demo.hasura.app/"
                tutorialLabel="Read the tutorial first"
                tutorialLink="https://github.com/hasura/sample-apps/tree/main/serverless-push"
                videoLink="https://www.youtube.com/embed/nuSHkzE2-zo?showinfo=0&rel=0"
                videoTitle="notifications"
              />
            </TabPanel>
            <TabPanel className="commonTabPanel">
              <TabPanelList
                description="Transform and load data into external data-stores."
                subDescription="Check out this demo and tutorial below to see how Postgres data is transformed to build and populate an Algolia index."
                isBtn
                btnLabel="Try it out"
                btnLink="https://serverless-etl.demo.hasura.app/"
                tutorialLabel="Watch the tutorial first"
                tutorialLink="https://github.com/hasura/sample-apps/tree/main/serverless-etl"
                videoLink="https://www.youtube.com/embed/fGAgi052RRU?showinfo=0&rel=0"
                videoTitle="data-transformation"
              />
            </TabPanel>
            <TabPanel className="commonTabPanel">
              <TabPanelList
                description="Deploy a static site with content backed by Postgres."
                subDescription="Whenever data is updated in Postgres, the site is rebuilt and redeployed automatically. Watch this talk using Gatsby, Netlify and Event Triggers."
                isBtn={false}
                btnLabel=""
                btnLink=""
                tutorialLabel="Read the tutorial"
                tutorialLink="https://github.com/coco98/jamstack"
                videoLink="https://www.youtube.com/embed/CWHek7Cwr7Y?showinfo=0&rel=0"
                videoTitle="update-static-sites"
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default BuildReactive;
