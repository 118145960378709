import React from "react";
import "./styles.scss";
const AtomicReliable = () => {
  return (
    <section id="atomic-reliable" className="commonSectionWrapper commonGrayBgColor">
      <div className="containerWrapper">
        <div className="atomicWrapper">
          <h2 className="articleTitle">Atomic & Reliable - Never miss an event</h2>
          <div className="articleDesc fontBold">
            Using native Postgres triggers, we capture every relevant write on the database as an
            event.
          </div>
          <div className="articleDesc">
            Even if Hasura is down or being updated, events will be delivered as soon as possible
            with an atleast-once guarantee.
          </div>
          <p className="articleDesc removePaddBottom">
            Configure delivery policies like <span className="fontBold">max_retries</span> and{" "}
            <span className="fontBold">retry_interval</span>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AtomicReliable;
