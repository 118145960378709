import React from "react";
import PropTypes from "prop-types";
import tick from "./images/tick.svg";
import quoteLeft from "../userstories/images/quote-left.svg";

const CommonVideoWrapper = ({
  title,
  description,
  subDescription,
  arrowLink,
  videoLink,
  bgClass,
  idLink,
  list,
  reverse,
  videoTitle,
  imgSrc,
  imgAlt,
  imgText,
  quote,
  authorImg,
  author,
  authorDesignation,
  removePaddTop,
  removePaddBottom,
  descriptionList,
  singleColumn,
  imgMarTop,
}) => {
  return (
    <section
      id={idLink}
      className={
        (bgClass ? "commonSectionWrapper " + bgClass : "commonSectionWrapper") +
        (removePaddTop ? " removePaddTop" : "") +
        (removePaddBottom ? " removePaddBottom" : "")
      }
    >
      <div className="containerWrapper">
        <div className={"commonVideoWrapper alignCenter" + (imgMarTop ? " alignStart" : "")}>
          <div
            className={
              singleColumn
                ? "col-md-12 col-sm-12 col-xs-12 noPadd"
                : "col-md-6 col-sm-6 col-xs-12 noPadd" +
                  (reverse ? " col-md-push-6 addPaddLeft" : " addPaddRight")
            }
          >
            {title}
            {description ? <div className="articleDesc">{description}</div> : null}
            {subDescription ? <div className="articleDesc">{subDescription}</div> : null}
            {list ? (
              <ul className="articleDesc">
                {list.map((item, key) => {
                  const itemWrapper = item.link ? (
                    <li key={key} className="removeHover">
                      <img src={tick} alt="tick" />
                      <a href={item.link} target={"_blank"} rel="noopener noreferrer">
                        <span>{item.desc}</span>
                      </a>
                    </li>
                  ) : (
                    <li key={key}>
                      <img src={tick} alt="tick" />
                      {item.desc}
                    </li>
                  );
                  return [itemWrapper];
                })}
              </ul>
            ) : null}
            {descriptionList ? (
              <div>
                {descriptionList.map((lists, index) => {
                  return (
                    <div key={index} className="articleDesc addPaddBottomVideo">
                      {lists.desc}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {quote && authorImg && author && authorDesignation ? (
              <div className="quoteWrapper">
                <img className="quoteLeft" src={quoteLeft} alt="Quote" />
                <div className="articleDesc quotePadd">{quote}</div>
                <div className="profileWrapper">
                  <div className="profileImg">
                    <img loading="lazy" src={authorImg} alt={author} />
                  </div>
                  <div>
                    <div className="articleSubTitle removePaddBottom">{author}</div>
                    {authorDesignation}
                  </div>
                </div>
              </div>
            ) : null}
            {arrowLink ? (
              <div className="commonLink">
                <a href={arrowLink} target={"_blank"} rel="noopener noreferrer">
                  Read More <div className="arrow">→</div>
                </a>
              </div>
            ) : null}
          </div>
          <div className={"col-md-6 col-sm-6 col-xs-12 noPadd" + (reverse ? " col-md-pull-6" : "")}>
            {videoLink ? (
              <div className="videoWrapper">
                <div className="videoAspectRatio">
                  <iframe
                    loading="lazy"
                    title={videoTitle}
                    src={videoLink}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                  {imgText ? <div className="articleDescSmall">{imgText}</div> : null}
                </div>
              </div>
            ) : null}
            {imgSrc ? (
              <div className={"commonImg" + (imgMarTop ? " commonImgRemoveMarTop" : "")}>
                <img loading="lazy" src={imgSrc} alt={imgAlt} />
                {imgText ? <div className="articleDescSmall">{imgText}</div> : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

CommonVideoWrapper.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  arrowLink: PropTypes.string,
  videoLink: PropTypes.string,
  bgClass: PropTypes.string.isRequired,
  idLink: PropTypes.string,
  list: PropTypes.array,
  reverse: PropTypes.bool,
  videoTitle: PropTypes.string,
  singleColumn: PropTypes.bool,
};

export default CommonVideoWrapper;
