import React from "react";
import "./styles.scss";
import azure from "./images/azure.svg";
import zeit from "./images/zeit.svg";
import gcp from "./images/gcp.svg";
import aws from "./images/aws.svg";
const CreateEventTrigger = () => {
  return (
    <section id="webhooks" className="commonSectionWrapper">
      <div className="containerWrapper">
        <div className="createTriggerWrapper">
          <div className="triggerWrapper wd100">
            <div className="col-md-6 col-sm-6 col-xs-12 col-md-push-6 noPadd addPaddLeft">
              <h2 className="articleSubTitle">Create an event-trigger & webhook in 60 seconds</h2>
              <div className="articleDesc fontBold">
                The Hasura console makes it extremely easy for you to specify a new event-trigger
                and then link it to a webhook.
              </div>
              <div className="articleDesc">
                Use one of our{" "}
                <a
                  href="https://github.com/hasura/graphql-engine/tree/master/community/boilerplates/event-triggers"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  serverless boilerplates
                </a>{" "}
                to deploy your webhook that can capture the event.
              </div>
              <ul className="articleDesc removePaddBottom">
                <li>
                  <a
                    href="https://github.com/hasura/graphql-engine/tree/master/community/boilerplates/event-triggers/azure-functions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <img src={azure} alt="Azure" />
                    </div>
                    <div className="articleDescSmall">AZURE</div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/hasura/graphql-engine/tree/master/community/boilerplates/event-triggers/zeit-serverless-docker"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <img src={zeit} alt="Zeit" />
                    </div>
                    <div className="articleDescSmall">ZEIT</div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/hasura/graphql-engine/tree/master/community/boilerplates/event-triggers/google-cloud-functions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <img src={gcp} alt="gcp" />
                    </div>
                    <div className="articleDescSmall">GCP</div>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/hasura/graphql-engine/tree/master/community/boilerplates/event-triggers/aws-lambda"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <img src={aws} alt="AWS" />
                    </div>
                    <div className="articleDescSmall">AWS</div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 col-md-pull-6 noPadd">
              <div className="videoWrapper">
                <div className="videoAspectRatio">
                  <iframe
                    loading="lazy"
                    title="create-an-event-trigger"
                    src="https://www.youtube.com/embed/EaTUVWnDCvA?showinfo=0&rel=0"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateEventTrigger;
